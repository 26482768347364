<template>
<div>
  <div style="margin-top: 80px;position: relative;">
    <el-row>
      <div class="container" style="margin-bottom: 30px">
        <!--企业信息-->
        <div style="margin-bottom: 30px;" class="condition-content">
          <div class="dis-bet-cen condition-title">
            <span class="screening-conditions-title" style="font-size: 16px;color: #666666;">企业信息</span>
          </div>
          <el-row v-if="tags.company.length > 0" class="dis-cen-sta" style="margin-top: 10px;">
            <el-col :span="2"><div class="grid-content bg-purple font-choose-text">已选条件：</div></el-col>
            <el-col :span="20">
              <div class="grid-content bg-purple dis-sta-cen" style="flex-wrap: wrap;">
                <el-tag style="margin-right: 10px;margin-bottom: 10px;" v-for="(tag, index) in tags.company" :key="tag.name" closable :type="tag.type" @close="closeTag(tag, index)">
                  {{tag.name}}
                </el-tag>
              </div>
            </el-col>
            <el-col :span="2">
              <div @click="closeAll('company', true)" class="grid-content bg-purple font-choose-text">清除全部</div>
            </el-col>
          </el-row>
          <el-row class="dis-sta-cen magin-top-bottom" :style="tags.company.length > 0 ? 'margin-top: 0px;' : 'margin-top: 10px;'">
            <el-col :span="2">
              <div class="grid-content bg-purple" style="font-size: 14px;color: #999999;" >企业信息：</div></el-col>
            <el-col :span="4" style="margin-right: 10px">
              <el-cascader
                ref="cascader"
                clearable
                collapse-tags
                separator=">"
                filterable="true"
                placeholder="请选择注册地"
                style="width: 100%;"
                v-model="companyRegistered"
                size="medium"
                :options="companyRegisteredValueArr"
                :props="props.companyRegistered"
                @change="companyRegisteredHandleChange"
                ></el-cascader>
            </el-col>
            <el-col :span="4" style="margin-right: 10px">
              <el-cascader
                ref="cascader"
                clearable
                collapse-tags
                separator=">"
                filterable="true"
                placeholder="请选择备案地"
                style="width: 100%;"
                v-model="companyRecord"
                size="medium"
                :options="companyRecordValueArr"
                :props="props.companyRegistered"
                @change="companyRecordHandleChange"
              ></el-cascader>
            </el-col>
            <el-col :span="4" style="margin-right: 10px">
              <el-input clearable :maxlength="8" @input="companyMoney = companyMoney.match(/\d+(\.\d{0,4})?/) ? companyMoney.match(/\d+(\.\d{0,4})?/)[0] : ''" v-model="companyMoney" size="medium" placeholder="请输入注册资金 ≥ 万" style="width: 100%;">
              </el-input>
            </el-col>
            <el-col :span="8" style="margin-right: 20px">
              <el-button size="medium" style="margin: 0px 0px 0px 46px;width: 150px;float: left;" type="primary" @click="companySubmit" :loading="loading.companySubmit">加入筛选</el-button>
            </el-col>
          </el-row>
        </div>
        <!--企业资质-->
        <div style="margin-bottom: 30px;" class="condition-content">
          <div class="dis-bet-cen condition-title">
            <span class="screening-conditions-title" style="font-size: 16px;color: #666666;">企业资质</span>
            <div class="divLeft" v-if="tags.credential.length > 1">
              <el-radio-group v-model="radio.credential" @change="radioChange(radio.credential, 'credential')" size="small" text-color="#666666" fill="#E1E2E6">
                <el-row :gutter="30">
                  <el-col :span="10"><el-radio-button label="1">符合全部</el-radio-button></el-col>
                  <el-col :span="10"><el-radio-button label="0">符合任意</el-radio-button></el-col>
                </el-row>
              </el-radio-group>
            </div>
          </div>
          <el-row v-if="tags.credential.length > 0" class="dis-cen-sta" style="margin-top: 10px;">
            <el-col :span="2"><div class="grid-content bg-purple font-choose-text">已选条件：</div></el-col>
            <el-col :span="20">
              <div class="grid-content bg-purple dis-sta-cen" style="flex-wrap: wrap;">
                <el-tag style="margin-right: 10px;margin-bottom: 10px;" v-for="(tag, index) in tags.credential" :key="tag.name" closable :type="tag.type" @close="closeTag(tag, index)">
                  {{tag.name}}
                </el-tag>
              </div>
            </el-col>
            <el-col :span="2">
              <div @click="closeAll('credential', true)" class="grid-content bg-purple font-choose-text">清除全部</div>
            </el-col>
          </el-row>
          <el-row class="dis-sta-cen magin-top-bottom" :style="tags.credential.length > 0 ? 'margin-top: 0px;' : 'margin-top: 10px;'">
            <el-col :span="2">
              <div class="grid-content bg-purple" style="font-size: 14px;color: #999999;" >企业资质：</div>
            </el-col>
            <el-col :span="7" style="margin-right: 10px">
              <div class="grid-content bg-purple">
                <el-cascader
                  ref="cascader"
                  clearable
                  collapse-tags
                  separator=">"
                  filterable="true"
                  placeholder="请选择资质分类"
                  style="width: 100%;"
                  v-model="companyCredentialClassify"
                  size="medium"
                  :options="companyCredentialClassifyValueArr"
                  :props="props.companyCredentialClassify"
                  @change="companyCredentialClassifyHandleChange"
                ></el-cascader>
              </div>
            </el-col>
            <el-col :span="14" style="margin-right: 20px">
              <el-button size="medium" style="margin: 0px 0px 0px 46px;width: 150px;float: left" type="primary" @click="credentialSubmit()" :loading="loading.credentialSubmit">加入筛选</el-button>
            </el-col>
          </el-row>
        </div>
        <!--荣誉证书-->
        <div style="margin-bottom: 30px;" class="condition-content">
          <div class="dis-bet-cen condition-title">
            <span class="screening-conditions-title" style="font-size: 16px;color: #666666;">荣誉证书</span>
            <div class="divLeft" v-if="tags.honorImage.length > 1">
              <el-radio-group v-model="radio.honorImage" @change="radioChange(radio.honorImage, 'honorImage')" size="small" text-color="#666666" fill="#E1E2E6">
                <el-row :gutter="30">
                  <el-col :span="10"><el-radio-button label="1">符合全部</el-radio-button></el-col>
                  <el-col :span="10"><el-radio-button label="0">符合任意</el-radio-button></el-col>
                </el-row>
              </el-radio-group>
            </div>
          </div>
          <el-row v-if="tags.honorImage.length > 0" class="dis-cen-sta" style="margin-top: 10px;">
            <el-col :span="2"><div class="grid-content bg-purple font-choose-text" >已选条件：</div></el-col>
            <el-col :span="20">
              <div class="grid-content bg-purple dis-sta-cen" style="flex-wrap: wrap;">
                <el-tag style="margin-right: 10px;margin-bottom: 10px;" v-for="(tag, index) in tags.honorImage" :key="tag.name" closable :type="tag.type" @close="closeTag(tag, index)">
                  {{tag.name}}
                </el-tag>
              </div>
            </el-col>
            <el-col :span="2">
              <div @click="closeAll('honorImage', true)" class="grid-content bg-purple font-choose-text">清除全部</div>
            </el-col>
          </el-row>
          <el-row class="dis-sta-cen magin-top-bottom" :style="tags.honorImage.length > 0 ? 'margin-top: 0px;' : 'margin-top: 10px;'">
            <el-col :span="2">
              <div class="grid-content bg-purple" style="font-size: 14px;color: #999999;" >荣誉证书：</div>
            </el-col>
            <el-col :span="4" style="margin-right: 10px">
              <el-select style="width: 100%;" placeholder="请选择荣誉类型" size="medium" clearable v-model="honorImageType">
                <el-option
                  v-for="item in honorImageTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col v-show="honorImageType === '1' || honorImageType === '2'" :span="4" style="margin-right: 10px">
              <el-cascader
                ref="cascader"
                clearable
                collapse-tags
                separator=">"
                filterable="true"
                placeholder="请选择荣誉奖项"
                style="width: 100%;"
                v-model="honorImageAward"
                size="medium"
                :options="honorImageAwardValueArr"
                :props="props.honorImageAward"
                @change="honorImageAwardHandleChange"
              ></el-cascader>
            </el-col>
            <el-col :span="6" style="margin-right: 20px">
              <el-date-picker
                style="float: left;"
                v-model="honorImageDate"
                type="monthrange"
                :editable="false"
                size="medium"
                @blur="achievementDateChange"
                range-separator="至"
                start-placeholder="开始月份"
                end-placeholder="结束月份">
              </el-date-picker>
            </el-col>
            <el-col :span="6" style="margin-right: 20px">
              <el-button size="medium" style="margin: 0px 0px 0px 46px;width: 150px;float: left" type="primary" @click="honorImageSubmit" :loading="loading.honorImageSubmit">加入筛选</el-button>
            </el-col>
          </el-row>
        </div>
        <!--技术人员-->
        <div style="margin-bottom: 30px;" class="condition-content">
          <div class="dis-bet-cen condition-title">
            <span class="screening-conditions-title" style="font-size: 16px;color: #666666;">技术人员</span>
            <div class="divLeft" v-if="tags.registeredPersonnel.length > 1">
              <el-radio-group v-model="radio.registeredPersonnel" @change="radioChange(radio.registeredPersonnel, 'registeredPersonnel')" size="small" text-color="#666666" fill="#E1E2E6">
                <el-row :gutter="30">
                  <el-col :span="10"><el-radio-button label="1">符合全部</el-radio-button></el-col>
                  <el-col :span="10"><el-radio-button label="0">符合任意</el-radio-button></el-col>
                </el-row>
              </el-radio-group>
            </div>
          </div>
          <el-row v-if="tags.registeredPersonnel.length > 0" class="dis-cen-sta" style="margin-top: 10px;">
            <el-col :span="2">
              <div class="grid-content bg-purple font-choose-text" >已选条件：</div>
            </el-col>
            <el-col :span="20">
              <div class="grid-content bg-purple dis-sta-cen" style="flex-wrap: wrap;">
                <el-tag style="margin-right: 10px;margin-bottom: 10px;" v-for="(tag, index) in tags.registeredPersonnel" :key="tag.name" closable :type="tag.type" @close="closeTag(tag, index)">
                  {{tag.name}}
                </el-tag>
              </div>
            </el-col>
            <el-col :span="2">
              <div @click="closeAll('registeredPersonnel', true)" class="grid-content bg-purple font-choose-text">清除全部</div>
            </el-col>
          </el-row>
          <el-row class="dis-sta-cen magin-top-bottom" :style="tags.registeredPersonnel.length > 0 ? 'margin-top: 0px;' : 'margin-top: 10px;'">
            <el-col :span="2">
              <div class="grid-content bg-purple" style="font-size: 14px;color: #999999;" >技术人员：</div>
            </el-col>
            <el-col :span="7" style="margin-right: 10px">
              <div class="grid-content bg-purple">
                <el-cascader
                  ref="cascader"
                  clearable
                  collapse-tags
                  separator=">"
                  filterable="true"
                  placeholder="请选择人员分类"
                  style="width: 100%;"
                  v-model="registeredPersonnelClassify"
                  size="medium"
                  :options="registeredPersonnelClassifyValueArr"
                  :props="props.registeredPersonnelClassify"
                  @change="registeredPersonnelClassifyHandleChange"
                ></el-cascader>
              </div>
            </el-col>
            <el-col :span="14" style="margin-right: 20px">
              <el-button size="medium" style="margin: 0px 0px 0px 46px;width: 150px;float: left" type="primary" @click="registeredPersonnelSubmit" :loading="loading.registeredPersonnelSubmit">加入筛选</el-button>
            </el-col>
          </el-row>
        </div>
        <!--中标项目-->
        <div style="margin-bottom: 30px;" class="condition-content">
          <div class="dis-bet-cen condition-title">
            <span class="screening-conditions-title" style="font-size: 16px;color: #666666;">中标项目</span>
            <div class="divLeft" v-if="tags.achievement.length > 1">
              <el-radio-group v-model="radio.achievement" @change="radioChange(radio.achievement, 'achievement')" size="small" text-color="#666666" fill="#E1E2E6">
                <el-row :gutter="30">
                  <el-col :span="10"><el-radio-button label="1">符合全部</el-radio-button></el-col>
                  <el-col :span="10"><el-radio-button label="0">符合任意</el-radio-button></el-col>
                </el-row>
              </el-radio-group>
            </div>
          </div>
          <el-row v-if="tags.achievement.length > 0" class="dis-cen-sta" style="margin-top: 10px;">
            <el-col :span="2"><div class="grid-content bg-purple font-choose-text" >中标项目：</div></el-col>
            <el-col :span="20">
              <div class="grid-content bg-purple dis-sta-cen" style="flex-wrap: wrap;">
                <el-tag style="margin-right: 10px;margin-bottom: 10px;" v-for="(tag, index) in tags.achievement" :key="tag.name" closable :type="tag.type" @close="closeTag(tag, index)">
                  {{tag.name}}
                </el-tag>
              </div>
            </el-col>
            <el-col :span="2">
              <div @click="closeAll('achievement', true)" class="grid-content bg-purple font-choose-text">清除全部</div>
            </el-col>
          </el-row>
          <el-row class="dis-sta-cen magin-top-bottom" :style="tags.achievement.length > 0 ? 'margin-top: 0px;margin-bottom: 10px;' : 'margin-top: 10px;margin-bottom: 10px;'">
            <el-col :span="2">
              <div class="grid-content bg-purple" style="font-size: 14px;color: #999999;" >中标项目：</div>
            </el-col>
            <el-col :span="4" style="margin-right: 10px">
              <div class="grid-content bg-purple">
                <el-input clearable v-model="achievementProjectKeyword" size="medium" placeholder="请输入项目名称关键字" style="width: 100%;"></el-input>
              </div>
            </el-col>
            <el-col :span="6" style="margin-right: 10px">
              <el-date-picker
                clearable
                style="float: left;"
                v-model="achievementDate"
                type="monthrange"
                :editable="false"
                size="medium"
                @blur="achievementDateChange"
                range-separator="至"
                start-placeholder="开始月份"
                end-placeholder="结束月份">
              </el-date-picker>
            </el-col>
            <el-col :span="3" style="margin-right: 10px">
              <el-cascader
                ref="cascader"
                clearable
                collapse-tags
                separator=">"
                filterable="true"
                placeholder="请选择项目地区"
                style="width: 100%;"
                v-model="companyAchievementAddress"
                size="medium"
                :options="companyAchievementAddressValueArr"
                :props="props.companyAchievementAddress"
                @change="companyAchievementAddressHandleChange"
              ></el-cascader>
            </el-col>
            <el-col :span="3" style="margin-right: 10px">
              <el-select clearable style="width: 100%;" placeholder="请选择专业分类" size="medium" v-model="companyAchievementProfessionalType">
                <el-option
                  v-for="item in companyAchievementProfessionalTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="3" style="margin-right: 10px">
              <el-select clearable style="width: 100%;" placeholder="请选择行业分类" size="medium" v-model="companyAchievementIndustryType">
                <el-option
                  v-for="item in companyAchievementIndustryTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row class="dis-sta-cen" style="margin-bottom: 20px;">
            <el-col :span="2">
              <div class="grid-content bg-purple" style="font-size: 14px;color: #999999;" >中标金额：</div>
            </el-col>
            <el-col :span="13" style="margin-right: 10px">
              <div class="grid-content bg-purple">
                <registered-capital ref="achievementMoneyRegisteredCapital" @handleChange="registeredCapitalChange"></registered-capital>
              </div>
            </el-col>
            <el-col :span="4" style="margin-right: 20px">
              <el-button size="medium" style="margin: 0px 0px 0px 46px;width: 150px;" type="primary" @click="achievementSubmit" :loading="loading.achievementSubmit">加入筛选</el-button>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-row>
  </div>
  <el-affix position="bottom" :offset="0">
    <el-row style="width: 100%;background-color: #fff;">
      <el-col :span="24">
        <div class="container dis-bet-cen" style="height: 68px;line-height: 68px;">
          <div>
            共查询到 <span class="font-color-red">{{ totalNumber &lt; 1900000 && totalNumber &gt; 1400000 ? 1954381 : totalNumber }}</span> 条符合条件的企业
          </div>
          <div class="dis-sta-cen">
            <div v-show="!idToken" style="color: #666666;font-size: 16px;margin-right: 20px;">普通会员用户仅可查询3次，立即<span style="color: #5D6FE9;cursor: pointer;" @click="handleToVip()">开通会员</span>></div>
            <div>
              <el-button @click="closeAll('', false)">清除选项</el-button>
              <el-button style="background-color: #5D6FE9;" type="success" @click="queryResultsClick">查询结果</el-button>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-affix>
</div>
</template>

<script>
import { parseTime } from '@/utils/index' // 工具类
import { ElMessage } from 'element-plus'
import {
  getRelevancy,
  getMatchingAllMayHonorRelevancy,
  getAllArtisanRelevancy
} from '@/api/query.js'
import { getDictByModel } from '@/api/dict'
import { forwardBG } from '@/api/forward'
import RegisteredCapital from '@/components/query-conditions/registered-capital' // 注册资金
import provinceData from '@/assets/pca-code.js'
import { mapGetters } from 'vuex'
export default {
  name: 'high-query-conditions',
  components: {
    RegisteredCapital
  },
  setup (props, context) {
    function loginEmit () {
      context.emit('loginEmit', 'topNav')
    }
    return {
      loginEmit
    }
  },
  data () {
    return {
      // 所有按钮加载中
      loading: {
        companySubmit: false,
        credentialSubmit: false,
        honorImageSubmit: false,
        registeredPersonnelSubmit: false,
        achievementSubmit: false
      },
      // 0: 符合全部 1:符合任意
      radio: {
        company: '1',
        credential: '0',
        honorImage: '0',
        registeredPersonnel: '0',
        achievement: '0'
      },
      // 标签已选条件
      tags: {
        company: [],
        credential: [],
        honorImage: [],
        registeredPersonnel: [],
        achievement: []
      },
      props: {
        companyRegistered: { expandTrigger: 'hover', value: 'code', label: 'name', checkStrictly: true },
        companyCredentialClassify: { expandTrigger: 'hover' },
        honorImageAward: { expandTrigger: 'hover' },
        registeredPersonnelClassify: { expandTrigger: 'hover' },
        companyAchievementAddress: { expandTrigger: 'hover', value: 'code', label: 'name' }
      },
      // 省市
      provinceData: provinceData,
      // 企业注册地 省市
      companyRegisteredValueArr: [],
      // 企业注册地 省
      companyRecordValueArr: [],
      // 企业资质 资质分类
      companyCredentialClassifyValueArr: [],
      // 荣誉证书 荣誉类型
      honorImageTypes: [
        {
          label: '行业荣誉',
          value: '1'
        }, {
          label: '工商荣誉',
          value: '2'
        }, {
          label: '税务荣誉',
          value: '3'
        }
      ],
      // 荣誉证书 荣誉奖项
      honorImageAwardValueArr: [],
      // 技术人员 人员分类
      registeredPersonnelClassifyValueArr: [],
      // 中标项目 项目地区
      companyAchievementAddressValueArr: [],
      // 中标项目 专业分类
      companyAchievementProfessionalTypes: [],
      // 中标项目 行业分类
      companyAchievementIndustryTypes: [],
      /**
       * 公司条件
       */
      companyRegistered: [], // 公司省市注册
      companyRecord: [], // 公司备案地
      companyMoney: '', // 公司注册资金
      /**
       * 资质条件
       */
      companyCredentialClassify: [], // 企业资质
      /**
       * 荣誉条件
       */
      honorImageType: '', // 选择的荣誉类型
      honorImageAward: [], // 荣誉奖项
      honorImageDate: '', // 荣誉获奖时间 格式 ["2021-11-30T16:00:00.000Z","2022-05-31T16:00:00.000Z"]
      /**
       * 技术人员条件
       */
      registeredPersonnelClassify: [], // 技术人员分类
      /**
       * 中标分类
       */
      achievementProjectKeyword: '', // 中标项目关键字
      achievementDate: '', // 中标时间
      companyAchievementAddress: [], // 中标项目地区
      companyAchievementProfessionalType: '', // 中标专业分类
      companyAchievementIndustryType: '', // 中标行业分类
      companyAchievementMoneyStart: '',
      companyAchievementMoneyEnd: '',
      achievementMoneyItem: {},
      /**
       * 条件
       */
      conditions: {
        company: {
          radio: '1', // 默认符合全部
          datas: []
        },
        credential: {
          radio: '0', // 默认符合任意
          datas: []
        },
        honorImage: {
          radio: '0', // 默认符合任意
          datas: []
        },
        registeredPersonnel: {
          radio: '0', // 默认符合任意
          datas: []
        },
        achievement: {
          radio: '0', // 默认符合全部
          datas: []
        }
      },
      queryConditions: {
        companyInfo: [],
        honorInfo: [],
        achievementInfo: [],
        credentialInfo: [],
        personnelInfo: [],
        credentialAllConform: '0',
        honorAllConform: '0',
        personnelAllConform: '0',
        achievementAllConform: '0',
        pageNum: 1,
        pageSize: 10
      },
      totalNumber: 0
    }
  },
  computed: {
    ...mapGetters([
      'idToken'
    ])
  },
  mounted () {
    const vm = this
    // 初始化企业信息注册地 省市  初始化企业信息备案地 省
    vm.initCompanyRegisteredValueArr()
    // 初始化企业资质的资质分类
    vm.initCompanyCredentialClassifyValueArr()
    // 初始化荣誉证书 荣誉奖项
    vm.initHonorImageAwardValueArr()
    // 初始化技术人员 人员分类
    vm.initRegisteredPersonnelClassifyValueArr()
    // 中标项目 项目地区
    vm.initCompanyAchievementAddressValueArr()
    // 中标项目 专业分类
    vm.initCompanyAchievementProfessionalTypes()
    // 中标项目 行业分类
    vm.initCompanyAchievementIndustryTypes()
    vm.dealWithConditions()
  },
  methods: {
    // 去开通vip
    handleToVip () {
      const vm = this
      const routeData = vm.$router.resolve({
        name: 'vip-index'
      })
      window.open(routeData.href, '_blank')
    },
    // 初始化企业信息注册地 省
    initCompanyRegisteredValueArr () {
      const vm = this
      const pcaData = vm.provinceData.pcaData
      const pcaDataRegistered = []
      const pcaDataRecord = []
      for (let i = 0; i < pcaData.length; i++) {
        const pcaDataItem = pcaData[i]
        const item = {}
        item.code = pcaDataItem.code
        item.name = pcaDataItem.name
        pcaDataRecord.push(JSON.parse(JSON.stringify(item)))
        if (pcaDataItem.children && pcaDataItem.children.length > 0) {
          item.children = []
          for (let j = 0; j < pcaDataItem.children.length; j++) {
            item.children.push({
              code: pcaDataItem.children[j].code,
              name: pcaDataItem.children[j].name
            })
          }
        }
        pcaDataRegistered.push(item)
      }
      vm.companyRegisteredValueArr = pcaDataRegistered
      vm.companyRecordValueArr = pcaDataRecord
    },
    initCompanyCredentialClassifyValueArr () {
      const vm = this
      getRelevancy().then(result => {
        if (result.code === 0) {
          const res = result.data
          vm.getRelevancyList(res, '0', function (a) {
            vm.companyCredentialClassifyValueArr = a
          })
        }
      }).catch(error => {
        console.log(error)
      })
    },
    initHonorImageAwardValueArr () {
      const vm = this
      const data = {
        type: vm.honorImageType ? vm.honorImageType : '1'
      }
      getMatchingAllMayHonorRelevancy(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          vm.getRelevancyList(res, '0', function (a) {
            vm.honorImageAwardValueArr = a
          })
        }
      }).catch(error => {
        console.log(error)
      })
    },
    initRegisteredPersonnelClassifyValueArr () {
      const vm = this
      getAllArtisanRelevancy().then(result => {
        if (result.code === 0) {
          const res = result.data
          vm.totalCascaderList = res
          vm.getRelevancyList(res, '0', function (a) {
            vm.registeredPersonnelClassifyValueArr = a
          })
        }
      }).catch(error => {
        console.log(error)
      })
    },
    initCompanyAchievementAddressValueArr () {
      const vm = this
      const pcaData = vm.provinceData.pcaData
      const pcaDataRegistered = []
      const pcaDataRecord = []
      for (let i = 0; i < pcaData.length; i++) {
        const pcaDataItem = pcaData[i]
        const item = {}
        item.code = pcaDataItem.code
        item.name = pcaDataItem.name
        pcaDataRecord.push(JSON.parse(JSON.stringify(item)))
        pcaDataRegistered.push(item)
      }
      vm.companyAchievementAddressValueArr = pcaDataRegistered
    },
    initCompanyAchievementProfessionalTypes () {
      const vm = this
      const data = {
        dictCodeLike: '0017'
      }
      getDictByModel(data).then(result => {
        if (result.code === 0) {
          const data = result.data ? result.data : []
          for (let i = 0; i < data.length; i++) {
            if (data[i].dictNo !== '0017') {
              vm.companyAchievementProfessionalTypes.push({
                label: data[i].dictName, // 显示的值
                value: data[i].dictNo // 实际的值
              })
            }
          }
        } else {
          return ElMessage.error(result.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    initCompanyAchievementIndustryTypes () {
      const vm = this
      const data = {
        dictCodeLike: '0014'
      }
      getDictByModel(data).then(result => {
        if (result.code === 0) {
          const data = result.data ? result.data : []
          for (let i = 0; i < data.length; i++) {
            if (data[i].dictNo !== '0014') {
              vm.companyAchievementIndustryTypes.push({
                label: data[i].dictName, // 显示的值
                value: data[i].dictNo // 实际的值
              })
            }
          }
        } else {
          return ElMessage.error(result.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    getRelevancyList: function (data, parentId, callback) {
      const arr = []
      const vm = this
      data.forEach((item, index) => {
        let obj = {}
        if (item.parentId === parentId) {
          obj = {
            value: item.id,
            label: item.name,
            parentId: item.parentId
          }
          if (vm.getRelevancyListChild(data, item, item.id).length > 0) {
            obj.children = vm.getRelevancyListChild(data, item, item.id)
          }
          arr.push(obj)
        }
      })
      callback(arr)
    },
    getRelevancyListChild: function (arr, parentItem, parentId) {
      const vm = this
      const arrs = []
      arr.forEach((item, index) => {
        let obj = {}
        if (item.parentId === parentId) {
          if (vm.getRelevancyListChild(arr, item, item.id).length > 0) {
            obj = {
              value: item.id,
              label: item.name,
              parentId: item.parentId,
              children: vm.getRelevancyListChild(arr, item, item.id)
            }
          } else {
            obj = {
              value: item.id,
              label: item.name,
              parentId: item.parentId
            }
          }
          arrs.push(obj)
        }
      })
      return arrs
    },
    // 公司 加入筛选 按钮
    companySubmit () {
      const vm = this
      vm.loading.companySubmit = true
      // 获取注册地信息 companyRegistered
      // 获取备案地信息 companyRecord
      // 获取注册资金 companyMoney
      if (vm.companyRegistered?.length > 0 || vm.companyRecord?.length > 0 || vm.companyMoney !== '') {
        const date = {}
        let tagName = ''
        if (vm.companyRegistered?.length > 0) {
          const thisCompanyRegistered = JSON.parse(JSON.stringify(vm.companyRegistered))
          const companyRegisteredStr = vm.treeToLabel(vm.companyRegisteredValueArr, thisCompanyRegistered, 'name', 'code')
          date.companyRegistered = companyRegisteredStr
          tagName += '注册地:' + companyRegisteredStr + '；'
        }
        if (vm.companyRecord?.length > 0) {
          const thisCompanyRecord = JSON.parse(JSON.stringify(vm.companyRecord))
          const companyRecordStr = vm.treeToLabel(vm.companyRecordValueArr, thisCompanyRecord, 'name', 'code')
          date.companyRecord = companyRecordStr
          tagName += '备案地:' + companyRecordStr + '；'
        }
        if (vm.companyMoney !== '') {
          date.companyMoney = vm.companyMoney
          tagName += '中标金额:≥' + vm.formatNum(vm.companyMoney) + '万；'
        }
        // 清理
        vm.companyRegistered = []
        vm.companyRecord = []
        vm.companyMoney = ''
        // 添加
        vm.conditions.company.datas.push(date)
        vm.tags.company.push({
          name: tagName,
          value: 'company'
        })
        vm.dealWithConditions()
      } else {
        ElMessage.warning({
          message: '请至少选择一个条件',
          type: 'warning'
        })
      }
      vm.loading.companySubmit = false
    },
    // 资质 加入筛选 按钮
    credentialSubmit () {
      const vm = this
      vm.loading.credentialSubmit = true
      // 获取企业资质 companyCredentialClassify
      if (vm.companyCredentialClassify?.length > 0) {
        const date = {}
        let tagName = ''
        if (vm.companyCredentialClassify?.length > 0) {
          const thisCompanyCredentialClassify = JSON.parse(JSON.stringify(vm.companyCredentialClassify))
          date.companyCredentialClassify = thisCompanyCredentialClassify
          tagName += '企业资质:' + vm.treeToLabel(vm.companyCredentialClassifyValueArr, thisCompanyCredentialClassify, 'label', 'value') + '；'
        }
        // 清理
        vm.companyCredentialClassify = []
        // 添加
        vm.conditions.credential.datas.push(date)
        vm.tags.credential.push({
          name: tagName,
          value: 'credential'
        })
        vm.dealWithConditions()
      } else {
        ElMessage.warning({
          message: '请至少选择一个条件',
          type: 'warning'
        })
      }
      vm.loading.credentialSubmit = false
    },
    // 荣誉 加入筛选 按钮
    honorImageSubmit () {
      const vm = this
      vm.loading.honorImageSubmit = true
      // 获取荣誉类型 honorImageType
      // 获取荣誉奖项 honorImageAward
      // 获取获奖时间 honorImageDate
      if (!vm.honorImageType) {
        ElMessage.warning({
          message: '请选择荣誉类型',
          type: 'warning'
        })
        vm.loading.honorImageSubmit = false
        return
      }
      if (vm.honorImageType !== '' || vm.honorImageAward?.length > 0 || (vm.honorImageDate !== '' && vm.honorImageDate?.length === 2)) {
        const date = {}
        let tagName = ''
        if (vm.honorImageType !== '') {
          date.honorImageType = vm.honorImageType
          let honorImageTypeStr = ''
          if (vm.honorImageType === '1') honorImageTypeStr = '行业荣誉'
          if (vm.honorImageType === '2') honorImageTypeStr = '工商荣誉'
          if (vm.honorImageType === '3') honorImageTypeStr = '税务荣誉'
          tagName += '荣誉类型:' + honorImageTypeStr + '；'
          date.honorImageTypeStr = honorImageTypeStr
        }
        if (vm.honorImageAward?.length > 0) {
          const thisHonorImageAward = JSON.parse(JSON.stringify(vm.honorImageAward))
          date.honorImageAward = thisHonorImageAward
          tagName += '荣誉奖项:' + vm.treeToLabel(vm.honorImageAwardValueArr, thisHonorImageAward, 'label', 'value') + '；'
        }
        if (vm.honorImageDate !== '' && vm.honorImageDate?.length === 2) {
          date.honorImageDateStart = parseTime(vm.honorImageDate[0], '{y}')
          date.honorImageDateEnd = parseTime(vm.honorImageDate[1], '{y}')
          tagName += '获奖时间:' + parseTime(vm.honorImageDate[0], '{y}年') + '-' + parseTime(vm.honorImageDate[1], '{y}年') + '；'
        }
        // 清理
        vm.honorImageType = ''
        vm.honorImageAward = []
        vm.honorImageDate = ''
        // 添加
        vm.conditions.honorImage.datas.push(date)
        vm.tags.honorImage.push({
          name: tagName,
          value: 'honorImage'
        })
        vm.dealWithConditions()
      } else {
        ElMessage.warning({
          message: '请至少选择一个条件',
          type: 'warning'
        })
      }
      vm.loading.honorImageSubmit = false
    },
    // 人员 加入筛选 按钮
    registeredPersonnelSubmit () {
      const vm = this
      vm.loading.registeredPersonnelSubmit = true
      // 获取技术人员分类 registeredPersonnelClassify
      if (vm.registeredPersonnelClassify?.length > 0) {
        const date = {}
        let tagName = ''
        if (vm.registeredPersonnelClassify?.length > 0) {
          const thisRegisteredPersonnelClassify = JSON.parse(JSON.stringify(vm.registeredPersonnelClassify))
          date.registeredPersonnelClassify = thisRegisteredPersonnelClassify
          tagName += '技术人员分类:' + vm.treeToLabel(vm.registeredPersonnelClassifyValueArr, thisRegisteredPersonnelClassify, 'label', 'value') + '；'
        }
        // 清理
        vm.registeredPersonnelClassify = []
        // 添加
        vm.conditions.registeredPersonnel.datas.push(date)
        vm.tags.registeredPersonnel.push({
          name: tagName,
          value: 'registeredPersonnel'
        })
        vm.dealWithConditions()
      } else {
        ElMessage.warning({
          message: '请至少选择一个条件',
          type: 'warning'
        })
      }
      vm.loading.registeredPersonnelSubmit = false
    },
    // 中标 加入筛选 按钮
    achievementSubmit () {
      const vm = this
      vm.loading.achievementSubmit = true
      // 获取中标项目关键字 achievementProjectKeyword
      // 获取中标时间 achievementDate
      // 获取中标项目地区 companyAchievementAddress
      // 获取中标中标专业分类 companyAchievementProfessionalType
      // 获取中标中标行业分类 companyAchievementIndustryType
      // 获取中标金额 companyAchievementMoney
      if (vm.achievementProjectKeyword !== '' ||
        (vm.achievementDate !== '' && vm.achievementDate?.length === 2) ||
        vm.companyAchievementAddress?.length > 0 ||
        vm.companyAchievementProfessionalType !== '' ||
        vm.companyAchievementIndustryType !== '' ||
        (vm.companyAchievementMoneyStart !== '' && vm.companyAchievementMoneyEnd !== '')) {
        const date = {}
        let tagName = ''
        if (vm.achievementProjectKeyword !== '') {
          date.achievementProjectKeyword = vm.achievementProjectKeyword
          tagName += '中标项目:' + vm.achievementProjectKeyword + '；'
        }
        if (vm.achievementDate !== '' && vm.achievementDate?.length === 2) {
          date.achievementDateStart = parseTime(vm.achievementDate[0], '{y}-{m}')
          date.achievementDateEnd = parseTime(vm.achievementDate[1], '{y}-{m}')
          tagName += '中标时间:' + parseTime(vm.achievementDate[0], '{y}年{m}月') + '-' + parseTime(vm.achievementDate[1], '{y}年{m}月') + '；'
        }
        if (vm.companyAchievementAddress?.length > 0) {
          const thisCompanyAchievementAddress = JSON.parse(JSON.stringify(vm.companyAchievementAddress))
          date.companyAchievementAddress = thisCompanyAchievementAddress
          tagName += '项目地区:' + vm.treeToLabel(vm.companyAchievementAddressValueArr, thisCompanyAchievementAddress, 'name', 'code') + '；'
        }
        if (vm.companyAchievementProfessionalType !== '') {
          date.companyAchievementProfessionalType = vm.companyAchievementProfessionalType
          let companyAchievementProfessionalTypeStr = ''
          for (let i = 0; i < vm.companyAchievementProfessionalTypes?.length; i++) {
            if (vm.companyAchievementProfessionalTypes[i].value === vm.companyAchievementProfessionalType) {
              companyAchievementProfessionalTypeStr = vm.companyAchievementProfessionalTypes[i].label
              break
            }
          }
          tagName += '专业分类:' + companyAchievementProfessionalTypeStr + '；'
        }
        if (vm.companyAchievementIndustryType !== '') {
          date.companyAchievementIndustryType = vm.companyAchievementIndustryType
          let companyAchievementIndustryTypeStr = ''
          for (let i = 0; i < vm.companyAchievementIndustryTypes?.length; i++) {
            if (vm.companyAchievementIndustryTypes[i].value === vm.companyAchievementIndustryType) {
              companyAchievementIndustryTypeStr = vm.companyAchievementIndustryTypes[i].label
              break
            }
          }
          tagName += '行业分类:' + companyAchievementIndustryTypeStr + '；'
        }
        if (vm.companyAchievementMoneyStart !== '' && vm.companyAchievementMoneyEnd !== '') {
          date.companyAchievementMoneyStart = vm.companyAchievementMoneyStart
          date.companyAchievementMoneyEnd = vm.companyAchievementMoneyEnd
          tagName += '中标金额:' + vm.achievementMoneyItem.key + '；'
        }
        // 清理
        vm.$refs.achievementMoneyRegisteredCapital.closeSelect()
        vm.achievementProjectKeyword = ''
        vm.achievementDate = ''
        vm.companyAchievementAddress = []
        vm.companyAchievementProfessionalType = ''
        vm.companyAchievementIndustryType = ''
        vm.companyAchievementMoneyStart = ''
        vm.companyAchievementMoneyEnd = ''
        // 添加
        vm.conditions.achievement.datas.push(date)
        vm.tags.achievement.push({
          name: tagName,
          value: 'achievement'
        })
        vm.dealWithConditions()
      } else {
        ElMessage.warning({
          message: '请至少选择一个条件',
          type: 'warning'
        })
      }
      vm.loading.achievementSubmit = false
    },
    radioChange (value, key) {
      const vm = this
      console.log(value + ' --- ' + key)
      vm.conditions[key].radio = value
      vm.dealWithConditions()
    },
    // 处理条件
    dealWithConditions () {
      const vm = this
      vm.queryConditions = {
        companyInfo: [],
        honorInfo: [],
        achievementInfo: [],
        credentialInfo: [],
        personnelInfo: [],
        credentialAllConform: '0',
        honorAllConform: '0',
        personnelAllConform: '0',
        achievementAllConform: '0',
        pageNum: 1,
        pageSize: 10
      }
      // 处理公司条件
      // const companyRadio = vm.conditions.company.radio
      const companyDatas = vm.conditions.company.datas
      for (let i = 0; i < companyDatas?.length; i++) {
        const recordProvince = companyDatas[i].companyRecord
        const province = companyDatas[i].companyRegistered ? companyDatas[i].companyRegistered.split('>')[0] : null
        const city = companyDatas[i].companyRegistered && companyDatas[i].companyRegistered.split('>')?.length === 2 ? companyDatas[i].companyRegistered.split('>')[1] : null
        const regMoneyStart = companyDatas[i].companyMoney ? companyDatas[i].companyMoney * 10000 : null
        vm.queryConditions.companyInfo.push({
          recordProvince: recordProvince,
          province: province,
          city: city,
          regMoneyStart: regMoneyStart
        })
      }
      // 处理企业资质条件
      const credentialRadio = vm.conditions.credential.radio
      const credentialDatas = vm.conditions.credential.datas
      for (let i = 0; i < credentialDatas?.length; i++) {
        const companyCredentialClassify = credentialDatas[i].companyCredentialClassify
        const subRelevancyId = companyCredentialClassify[(companyCredentialClassify?.length - 2) < 0 ? 0 : companyCredentialClassify?.length - 2]
        let qualificationGrade = null
        if (companyCredentialClassify) {
          const companyCredentialClassifyStr = vm.treeToLabel(vm.companyCredentialClassifyValueArr, companyCredentialClassify, 'label', 'value')
          const companyCredentialClassifyArr = companyCredentialClassifyStr.split('>')
          qualificationGrade = companyCredentialClassifyArr[companyCredentialClassifyArr?.length - 1]
        }
        vm.queryConditions.credentialInfo.push({
          subRelevancyId: subRelevancyId,
          qualificationGrade: qualificationGrade
        })
      }
      vm.queryConditions.credentialAllConform = credentialRadio
      // 处理荣誉证书条件
      const honorImageRadio = vm.conditions.honorImage.radio
      const honorImageDatas = vm.conditions.honorImage.datas
      for (let i = 0; i < honorImageDatas?.length; i++) {
        const honorType = honorImageDatas[i].honorImageType
        const honorTypeName = honorImageDatas[i].honorImageTypeStr
        const issueDataStart = honorImageDatas[i].honorImageDateStart
        const issueDataEnd = honorImageDatas[i].honorImageDateEnd
        let name = null
        if (honorImageDatas[i].honorImageAward) {
          const honorImageAwardStr = vm.treeToLabel(vm.honorImageAwardValueArr, honorImageDatas[i].honorImageAward, 'label', 'value')
          const honorImageAwardArr = honorImageAwardStr.split('>')
          name = honorImageAwardArr[honorImageAwardArr?.length - 1]
        }
        vm.queryConditions.honorInfo.push({
          honorType: honorType,
          honorTypeName: honorTypeName,
          issueDataStart: issueDataStart,
          issueDataEnd: issueDataEnd,
          name: name
        })
      }
      vm.queryConditions.honorAllConform = honorImageRadio
      // 技术人员
      const registeredPersonnelRadio = vm.conditions.registeredPersonnel.radio
      const registeredPersonnelDatas = vm.conditions.registeredPersonnel.datas
      for (let i = 0; i < registeredPersonnelDatas?.length; i++) {
        const registeredPersonnelClassify = registeredPersonnelDatas[i].registeredPersonnelClassify
        const registeredPersonnelClassifyStr = vm.treeToLabel(vm.registeredPersonnelClassifyValueArr, registeredPersonnelClassify, 'label', 'value')
        const registeredPersonnelClassifyArr = registeredPersonnelClassifyStr.split('>')
        const subRelevancyId = registeredPersonnelClassify[registeredPersonnelClassify?.length - 1]
        const subQualificationName = registeredPersonnelClassifyArr[registeredPersonnelClassifyArr?.length - 1]
        vm.queryConditions.personnelInfo.push({
          subRelevancyId: subRelevancyId,
          subQualificationName: subQualificationName
        })
      }
      vm.queryConditions.personnelAllConform = registeredPersonnelRadio
      // 中标项目
      const achievementRadio = vm.conditions.achievement.radio
      const achievementDatas = vm.conditions.achievement.datas
      for (let i = 0; i < achievementDatas?.length; i++) {
        const projectName = achievementDatas[i].achievementProjectKeyword
        const timeSuccessStart = achievementDatas[i].achievementDateStart
        const timeSuccessEnd = achievementDatas[i].achievementDateEnd
        const companyAchievementAddress = achievementDatas[i].companyAchievementAddress
        let province = null
        if (companyAchievementAddress) {
          province = vm.treeToLabel(vm.companyAchievementAddressValueArr, companyAchievementAddress, 'name', 'code')
        }
        const companyAchievementProfessionalType = achievementDatas[i].companyAchievementProfessionalType
        let industryTag = null
        for (let i = 0; i < vm.companyAchievementProfessionalTypes?.length; i++) {
          if (vm.companyAchievementProfessionalTypes[i].value === companyAchievementProfessionalType) {
            industryTag = vm.companyAchievementProfessionalTypes[i].label
            break
          }
        }
        const companyAchievementIndustryType = achievementDatas[i].companyAchievementIndustryType
        let business = null
        for (let i = 0; i < vm.companyAchievementIndustryTypes?.length; i++) {
          if (vm.companyAchievementIndustryTypes[i].value === companyAchievementIndustryType) {
            business = vm.companyAchievementIndustryTypes[i].label
            break
          }
        }
        const moneyStart = achievementDatas[i].companyAchievementMoneyStart
        const moneyEnd = achievementDatas[i].companyAchievementMoneyEnd
        vm.queryConditions.achievementInfo.push({
          business: business,
          moneyEnd: moneyEnd,
          moneyStart: moneyStart,
          projectName: projectName,
          province: province,
          industryTag: industryTag,
          timeSuccessEnd: timeSuccessEnd,
          timeSuccessStart: timeSuccessStart
        })
      }
      vm.queryConditions.achievementAllConform = achievementRadio
      console.log(JSON.stringify(vm.queryConditions))
      // 调用接口查询
      const data = {
        dateBackgroundUrl: '/esComplexInfo/getESComplexInfoCountByModel',
        dateBackgroundRequestType: 'post',
        data: vm.queryConditions
      }
      // 将条件放入sessionStorage
      sessionStorage.removeItem('high-query-conditions-item')
      sessionStorage.setItem('high-query-conditions-item', JSON.stringify(vm.queryConditions))
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const data = result.data ? result.data : []
          vm.totalNumber = data[0]?.dataTotal ? data[0].dataTotal : 0
        } else {
          return ElMessage.error(result.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 点击了查询结果
    queryResultsClick () {
      const vm = this
      if (vm.idToken) {
        // 跳转
        vm.$router.push({ name: 'high-query-lsit' })
      } else {
        vm.loginEmit()
      }
    },
    closeTag (tag, index) {
      const vm = this
      // 清理数据
      vm.tags[tag.value].splice(index, 1)
      vm.conditions[tag.value].datas.splice(index, 1)
      vm.dealWithConditions()
    },
    // 清除全部
    closeAll (name, isQuery) {
      const vm = this
      if (name) {
        vm.tags[name] = []
        vm.conditions[name].datas = []
        vm.conditions[name].radio = '1'
        if (isQuery) {
          vm.dealWithConditions()
        }
      } else {
        vm.closeAll('company', false)
        vm.closeAll('credential', false)
        vm.closeAll('honorImage', false)
        vm.closeAll('registeredPersonnel', false)
        vm.closeAll('achievement', false)
        vm.dealWithConditions()
      }
    },
    companyRegisteredHandleChange () {},
    companyRecordHandleChange () {},
    companyCredentialClassifyHandleChange () {},
    honorImageAwardHandleChange () {},
    registeredPersonnelClassifyHandleChange () {},
    companyAchievementAddressHandleChange () {},
    // 注册资金组件选择完了的回调
    registeredCapitalChange (item) {
      const vm = this
      vm.achievementMoneyItem = item
      vm.companyAchievementMoneyStart = item.startValue
      vm.companyAchievementMoneyEnd = item.endValue
    },
    // 如果数字最后一位是点就去掉
    formatNum (num) {
      if (num === null || num === undefined) {
        return num
      }
      let thisNum = num.toString()
      while (thisNum.substring(thisNum.length - 1, thisNum.length) === '.') {
        thisNum = thisNum.substring(0, thisNum.length - 1)
      }
      return thisNum * 1
    },
    treeToLabel (data, arr, key, value) {
      const result = []
      const childrenTreeToLabel = (data, arr, result) => {
        for (let j = 0; j < data.length; j++) {
          if (arr[0] === data[j][value]) {
            result.push(data[j][key])
            if (data[j].children && arr.length > 1) {
              childrenTreeToLabel(data[j].children, arr.slice(1), result)
            }
            break
          }
        }
      }
      childrenTreeToLabel(data, arr, result)
      return result.join('>')
    }
  }
}
</script>

<style scoped="scoped">
.magin-top-bottom {
  margin: 20px 0;
}
.font-choose-text {
  font-size: 14px;
  color: #5D6FE9;
  height: 40px;
  line-height: 40px;
}
</style>
